export const environment = {
  production: false,
  name: 'beta',
  production_lanes: false,
  locales: ['ru', 'en'],
  cms_url: 'https://$domain_path.trainingspace.online',
  apiEndpoint: 'https://auth$dot_domain_path.trainingspace.online',
  apiWebsocketEndpoint: 'wss://auth$dot_domain_path.trainingspace.online',
  loglevel: 'INFO',
  static_uploader: 'https://cdn$dot_domain_path.trainingspace.online',
  static_uploader_cms_domain: 'https://$domain_path.trainingspace.online/cdn',
  training_app_url: '',
  patterns_url: '',
  lrs_url: 'https://lrs$dot_domain_path.trainingspace.online',
  b2c_url: 'https://$domain_path.trainingspace.online/freelance',
  room_url: 'https://$domain_path.trainingspace.online/training/room',
  assessment_url: 'https://$domain_path.trainingspace.online/assessment/room',
  new_room_url: 'https://$domain_path.trainingspace.online/room',
  zoom_url: 'https://$domain_path.trainingspace.online/zoom/room',
  rootUrl: '/',
  cookie_domain: '$domain_path.trainingspace.online',
  websocket_worker_url: 'https://$domain_path.trainingspace.online',
  websocket_worker_path: '/websocket-worker/socket.io/',
  additional_material_api: 'https://$domain_path.trainingspace.online/additional-materials-api',
  raw_spreadsheet_url: 'https://$domain_path.trainingspace.online',
  spreadsheet_url: 'https://$domain_path.trainingspace.online/spreadsheet/',
  presentation_url: 'https://$domain_path.trainingspace.online/presentation/',
  flipchart_url: 'https://$domain_path.trainingspace.online/flipchart/',
  pdf_builder: 'https://$domain_path.trainingspace.online/pdf-builder',
  chat_api: 'https://$domain_path.trainingspace.online/chat-dev',
  comments_url: 'https://$domain_path.trainingspace.online',
  comments_path: '/comment-api/socket.io/',
  support_chat: 'https://api.helpdesk2.beta.oblakogroup.ru',
  support_chat_project_id: 'a107bf6f-3ea5-4087-8e98-99688a4fe315',
  support_chat_ws: 'wss://api.helpdesk2.beta.oblakogroup.ru/graphql',
  support_email: 'support@trainingspace.ru',
  support_phone_number: '+7 495 230-15-68',
  log_proxy: 'https://log-proxy$dot_domain_path.trainingspace.online/proxy/multiple',
  maintenance_api_url: 'https://$domain_path.trainingspace.online/maintenance-api',
  maintenance_excluded_organization_id: 'ea182672-0713-4db1-9435-0be294c95f20',
  notification_url: 'https://$domain_path.trainingspace.online/notification-dev',
  privacy_policy_link: 'https://www.trainingspace.online/confidential',
  privacy_policy_en_link: 'https://www.trainingspace.online/privacy',
  auth_zoom_url: 'https://auth$dot_domain_path.trainingspace.online/zoom/',
  lanes_landing_url: 'https://www.lanes.ai/',
  firebase_link_prefix: 'https://betatrainingspace.page.link/?apn=online.trainingspace.beta&ibi=online.trainingspace.beta&isi=1407303851&link=',
  firebase_link:
    'https://trainingspace.page.link/?apn=online.trainingspace&ibi=online.trainingspace&isi=1407303851' +
    '&sd=%D0%A2%D1%80%D0%B5%D0%BD%D0%B8%D0%BD%D0%B3%D0%B8%20%D0%B2%20%D1%80%D0%B5%D0%B6%D0%B8%D0%BC%D0%B5%20%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD&link=https%3A%2F%2Fapp.trainingspace.online%2F',

  // disable services in dev mode
  disable_chat: false,
  disable_maintenance: false,
  disable_websocket_worker: false,
};
