import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';

import 'moment/locale/ru';

function appInitializerConfigFn() {
  if (environment.name === 'production') {
    return Promise.resolve({});
  } else {
    const href = document.getElementsByTagName('base')[0].href;
    return fetch(href + 'assets/config.json', { method: 'GET' })
      .then((res) => res.json())
      .then((data) => {
        const env_entries = Object.entries(environment);

        if ('domain_path' in data) {
          const is_dot = ['beta', 'rc'].includes(data.domain_path);
          data.dot_domain_path = (is_dot ? '.' : '-') + data.domain_path;
        }

        Object.entries(data as object).forEach(([key, value_in]) => {
          env_entries.forEach(([k, v]) => {
            if (typeof v === 'string' && v.includes('$' + key)) {
              const value = ['domain_env', 'domain_path', 'dot_domain_path'].includes(key) && environment.name === 'rc' && value_in.includes('beta') ? value_in.replace('beta', 'rc') : value_in;
              environment[k] = v.replace('$' + key, value);
            }
          });
        });
      })
      .catch(() => ({}));
  }
}

appInitializerConfigFn().then(async () => {
  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic()
    .bootstrapModule((await import('./app/app.module')).AppModule)
    .catch((err) => console.error(err));
});
